/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Link from 'gatsby-link';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { ThemeProvider } from 'styled-components';

import theme from '../stylesheets/theme';

import Layout from '../components/layout';

const VarausJaPeeruStyles = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .terms-container {
    margin: 150px auto 0;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    padding: 0 16px;
  }
`;

const VarausJaPeeru = () => (
  <Layout
    seo={{
      title: 'Discover Lapland - Varaus- ja peruutusehdot',
      // keywords={post.tags}
      // url={`https://www.discoverlapland.fi/blog`}
      // image={`https:${post.headerImage.file.url}`}
    }}
  >
    <ThemeProvider theme={theme}>
      <VarausJaPeeruStyles>
        <div className="terms-container">
          <h2>VARAUS- JA PERUUTUSEHDOT</h2>
          <br />
          <p>
            {' '}
            Varaukset tehdään sähköpostitse{' '}
            <a className="bolded" href="mailto: discoverlapland@gmail.com">
              discoverlapland@gmail.com
            </a>{' '}
            tai{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            . Jos haluat peruuttaa retken, ota yhteyttä osoitteeseen{' '}
            <a className="bolded" href="mailto: discoverlapland@gmail.com">
              discoverlapland@gmail.com
            </a>{' '}
            tai{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
          </p>
          <br />
          <h3> Hetta-Pallas vaellus:</h3>
          <p>
            {' '}
            Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
            laskun. Kun lasku on maksettu, on paikka vaellukselle varattu ja
            saat jälleen paluupostina vaelluksen materiaalit.
          </p>
          <br />
          <p>
            Jos perut osallistumisesi vaellukselle missä kohtaa tahansa,
            pidätämme maksetusta laskusta toimitetut vaelluksen materiaalien
            kulut sekä toimistokulut 175€. Ilmoittautumisen peruutus tulee tehdä
            sähköpostitse osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
          </p>
          <br />
          <p>
            Jos perut retken 60 päivää ennen retken aloituspäivämäärää,
            palautamme sinulle 175€. <br />
            Jos perut retken alle 60 päivää, kuitenkin 30 päivää ennen retken
            aloituspäivää, palautamme sinulle 87,50€. <br />
            Jos perut retken 29 päivää tai alle ennen retken aloituspäivämäärää,
            ei palautusta.
          </p>
          <br />
          <h3> Haltin vaellus:</h3>
          <p>
            {' '}
            Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
            materiaalikululaskun 250€. Kun lasku on maksettu, on paikka
            hiihtovaellukselle varattu ja saat paluupostina hiihtovaelluksen
            materiaalit; pakkauslistan ja retkisuunnitelman sekä
            onlinetapaamisten linkit.
            <br />
            <br />
            Vaelluksen loppulaskun 570€ saat sähköpostiisi vaelluksen
            aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
            kuittikopio osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
            <br />
            <br />
            Jos perut osallistumisesi vaellukselle missä kohtaa tahansa,
            pidätämme toimitetut hiihtovaelluksen materiaalien kulut sekä
            toimistokulut 250€. Ilmoittautumisen peruutus tulee tehdä
            sähköpostitse osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
          </p>
          <br />
          <h3>Hetta-Pallas hiihtovaellukset:</h3>
          <p>
            Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
            materiaalikululaskun 250€. Kun lasku on maksettu, on paikka
            hiihtovaellukselle varattu ja saat paluupostina hiihtovaelluksen
            materiaalit; pakkauslistan ja retkisuunnitelman sekä
            onlinetapaamisten linkit.
            <br />
            <br />
            Hiihtovaelluksen loppulaskun 640€ saat sähköpostiisi
            hiihtovaelluksen aloituspäivänä, jolloin lasku tulee maksaa ja
            lähettää tästä kuittikopio osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
            <br />
            <br />
            Jos perut osallistumisesi hiihtovaellukselle missä kohtaa tahansa,
            pidätämme toimitetut hiihtovaelluksen materiaalien kulut sekä
            toimistokulut 250€. Ilmoittautumisen peruutus tulee tehdä
            sähköpostitse osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
          </p>
          <br />
          <h3>Haltin hiihtovaellus:</h3>
          <p>
            Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
            materiaalikululaskun 290€. Kun lasku on maksettu, on paikka
            hiihtovaellukselle varattu ja saat paluupostina hiihtovaelluksen
            materiaalit; pakkauslistan ja retkisuunnitelman sekä
            onlinetapaamisten linkit.
            <br />
            <br />
            Hiihtovaelluksen loppulaskun 1000€ saat sähköpostiisi
            hiihtovaelluksen aloituspäivänä, jolloin lasku tulee maksaa ja
            lähettää tästä kuittikopio osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
            <br />
            <br />
            Jos perut osallistumisesi hiihtovaellukselle missä kohtaa tahansa,
            pidätämme toimitetut hiihtovaelluksen materiaalien kulut sekä
            toimistokulut 290€. Ilmoittautumisen peruutus tulee tehdä
            sähköpostitse osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
            <br />
            <br />
            Jos lähisukulaisen tai matkan osapuolen äkillinen sairaus / vamma /
            kuolema voi johtaa peruuttamiseen, sinun on varmistettava, että
            matkavakuutuksesi kattaa varauksen kustannukset. Jopa tällaisissa
            tapauksissa varausehdot ovat edellä mainitut. Jos peruutat, ota heti
            yhteyttä osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            . Peruutustilanteessa osallistumisoikeutta ei voi siirtää tuleville
            vaelluksille.
            <br />
            <br />
            Asiakas on velvollinen olemaan ajallaan ohjelman aikataulun
            mukaisesti. Myöhästyminen ei oikeuta ohjelman palautusta tai
            uudelleenjärjestelyä. Ole lähtöpaikassa 10-15 min aikaisemmin kuin
            lähtöaika.
            <br />
            <br />
            HUOM! Pidäthän huolen, että sinulla on voimassa oleva vapaa-ajan
            sekä matkavakuutus retken aikana.
            <br />
            <br />
          </p>
          <h3>UK hiihtovaellus</h3>
          <p>
            Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
            materiaalikululaskun 350€. Kun lasku on maksettu, on paikka
            hiihtovaellukselle varattu ja saat paluupostina hiihtovaelluksen
            materiaalit; pakkauslistan ja retkisuunnitelman sekä
            onlinetapaamisten linkit.
            <br />
            <br />
            Hiihtovaelluksen loppulaskun 1000€ saat sähköpostiisi
            hiihtovaelluksen aloituspäivänä, jolloin lasku tulee maksaa ja
            lähettää tästä kuittikopio osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
            <br />
            <br />
            Jos perut osallistumisesi hiihtovaellukselle missä kohtaa tahansa,
            pidätämme toimitetut hiihtovaelluksen materiaalien kulut sekä
            toimistokulut 350€. Ilmoittautumisen peruutus tulee tehdä
            sähköpostitse osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
          </p>
          <br />
          <h3>Naisten FINLAV lumiturvallisuus 1 YLLÄS</h3>
          <p>
            Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
            laskun. Kun lasku on maksettu, on paikka kurssille varattu.
            <br />
            <br />
            Asiakkaan peruutukset 2 kuukautta ennen retken alkua: 100% palautus{' '}
            <br />
            Asiakkaan peruutukset 1 kuukausi ennen retken alkua: 50% palautus{' '}
            <br />
            Asiakkaan peruutukset alle kuukausi ennen retken alkua: Ei hyvitystä
            <br />
            <br />
            Jos lähisukulaisen tai matkan osapuolen äkillinen sairaus / vamma /
            kuolema voi johtaa peruuttamiseen, sinun on varmistettava, että
            matkavakuutuksesi kattaa varauksen kustannukset. Jopa tällaisissa
            tapauksissa varausehdot ovat edellä mainitut. Jos peruutat, ota heti
            yhteyttä osoitteeseen{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            . Peruutustilanteessa osallistumisoikeutta ei voi siirtää tuleville
            retkille.
            <br />
            <br />
            Asiakas on velvollinen olemaan ajallaan ohjelman aikataulun
            mukaisesti. Myöhästyminen ei oikeuta ohjelman palautusta tai
            uudelleenjärjestelyä. Ole lähtöpaikassa 10-15 min aikaisemmin kuin
            lähtöaika.
          </p>
          <br />
          <h3>MAASTOPYÖRÄVAELLUS SUOMI - RUOTSI - NORJA</h3>
          <p>
            Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
            materiaalikululaskun 140€. Kun lasku on maksettu, on paikka retkelle
            varattu ja saat paluupostina retken materiaalit.
          </p>
          <br />
          <p>
            MTB-vaellusken loppulaskun 540€ saat sähköpostiisi retken
            aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
            kuittikopio osoitteeseen{' '}
            <a href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
          </p>
          <br />
          <p>
            Jos perut osallistumisesi retkelle missä kohtaa tahansa, pidätämme
            toimitetut materiaalien kulut sekä toimistokulut 140€.
            Ilmoittautumisen peruutus tulee tehdä sähköpostitse osoitteeseen{' '}
            <a href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            .
          </p>
          <br />
          <h2>MUUTOKSET:</h2>
          <p>
            Kaikki varaukseen tehtävät muutokset on tehtävä sähköpostitse
            osoitteeseen Discoverlapland@gmail.com tai{' '}
            <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>
            . Pyydetyt muutokset edellyttävät Discover Laplandin kirjallista
            hyväksyntää.
          </p>
          <br />
          <h2>PALVELUNTUOTTAJAN VASTUU</h2>
          <p>
            Palveluntuottaja, Discover Lapland, harjoittaa toimintaansa
            asianmukaiset turvallisuussuunnitelmat sekä terveys- ja
            turvallisuusstandardit huomioiden Suomen lakien ja asetusten
            mukaisesti.
          </p>
          <br />
          <h2>MUUT VAHINGOT</h2>
          <p>
            Palveluntuottajaa voidaan pitää vastuussa ainoastaan henkilö- tai
            aineellisista vahingoista, jotka ovat tapahtuneet ohjelman/palvelun
            tuottamisen yhteydessä aiheutuneesta onnettomuudesta, joka on
            seurausta palveluntuottajan tai sen henkilökunnan tai alihankkijan
            virheellisestä toiminnasta tai viallisesta laitteesta.
            Palveluntuottaja ei ole vastuussa vahingoista tai vammoista, jotka
            ovat korvattavissa osallistujan omasta matka- tai
            vapaa-ajanvakuutuksesta.
            <br />
            <br />
            Osallistujalla tulee olla voimassa oleva vapaa-ajanvakuutus sekä
            matkavakuutus.
          </p>
          <br />
          <h2>RASKAUS JA MUUT TERVEYDELLISET SEIKAT</h2>
          <p>
            Raskaana oleva nainen osallistuu Discover Laplandin retkille omalla
            vastuullaan, hänen tulee kertoa raskaudestaan ilmoittautuessaan. Osa
            ohjelmista ja palveluista voi olla fyysisesti vaativia ja
            osallistujat voivat altistua erilaisille fyysisille rasituksille ja
            kuormituksille. Mikäli osallistuja epäilee terveydentilansa olevan
            esteenä ohjelmaan/palveluun osallistumiselle tai epäilee
            terveydentilansa vaarantuvan ohjelman/palvelun aikana, tulee
            osallistujan varmistaa lääkäriltä rajoittaako terveydentila hänen
            osallistumistaan. Asiakas osallistuu ohjelmiin/palveluihin aina
            omalla vastuullaan. Varaajan tulee ilmoittaa varauksen yhteydessä
            osallistujien terveydellisistä seikoista, jotka voivat vaikuttaa
            ohjelman suorittamiseen. Mikäli terveydellinen seikka vaatii
            erikoisjärjestelyjä ohjelman/palvelun suhteen (esimerkiksi erillisen
            kuljetuksen / toisen oppaan), on palveluntuottajalla oikeus
            veloittaa erikoisjärjestelyistä aiheutuvat ylimääräiset kulut
            asiakkaalta. Asiakas osallistuu ohjelmaan/palveluihin aina omalla
            vastuullaan.
          </p>
          <br />
          <h2>OIKEUTEMME PERUUTTAA VARAUS / RETKI</h2>
          <p>
            Pidätämme oikeuden peruuttaa varaus tai retki ylivoimaisen esteen
            tai muiden syiden vuoksi, jotka eivät ole Discover Laplandin
            hallinnassa. Hetta-Pallas vaellusten osalta maksetusta laskusta ei
            palauteta toimisto- ja materiaalikuluja, loppu summa palautetaan
            100%. Muiden retkien osalta Discover Laplandin peruessa retki missä
            kohtaa tahansa ennen retken aloituskohtaa, Discover Lapland pidättää
            maksetut materiaalikustannuksista lähetetyt laskut.
            <br /> <br />
            Pidätämme oikeuden perua varauksen, jos maksuja ei suoriteta
            ajallaan.
          </p>
          <br />
          <h2>SOVELLETTAVA LAKI JA RIITOJEN OIKEUSPAIKKA</h2>
          <p>
            Sopijapuolet pyrkivät ratkaisemaan sopimuksesta syntyneet riidat
            keskinäisin neuvotteluin. Mikäli neuvotteluissa ei päästä
            sopimukseen, asiakas voi viedä asian Kuluttajariitalautakuntaan tai
            Lapin käräjäoikeuteen. Sopimukseen sovelletaan Suomen lakia.
          </p>
        </div>
      </VarausJaPeeruStyles>
    </ThemeProvider>
  </Layout>
);

export default VarausJaPeeru;
